/* eslint-disable react/no-array-index-key */
import React from 'react'
import { styled } from '@mui/system'
import {
  CircularProgress,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Button
} from '@mui/material'
import { Add, ArrowLeft, ArrowRight } from '@mui/icons-material'
import { bool, func, array, arrayOf, shape } from 'prop-types'
import { useSelector } from 'react-redux'
import { TableHeaderCell } from '../TableHeaderCell'
import { formatTimestamp } from '../../utils/date-utils'
import { DSA_CATEGORIES } from '../../utils/reject-reasons'
import { settingsSelector } from '../../selectors/settings-selector'

export const TableColumn = {
  checkbox: ({ onChange, onChangeHeader, key }) => ({
    header: () => <Checkbox onChange={e => onChangeHeader(e.target.checked)} />,
    cell: row => <Checkbox onChange={() => onChange(row)} checked={row[key]} />
  }),
  userRoles: {
    header: () => <>Roles</>,
    cell: user => (
      <>
        {[
          [user.isSuperOperator, 'Super operator'],
          [user.isOperator, 'Operator'],
          [user.isModerator, 'Moderator']
        ]
          .filter(u => u[0])
          .map(u => u[1])
          .join(', ')}
      </>
    )
  },
  userNick: {
    header: () => <>Nick</>,
    cell: user => <>{user.nick}</>
  },
  banModerator: {
    header: () => <>Moderator</>,
    cell: row => <>{row.blockingUserNick}</>
  },
  banTime: {
    header: () => <>Banned</>,
    cell: row => <>{formatTimestamp(row.bannedAt)}</>
  },
  banReason: {
    header: () => <>Reason</>,
    cell: (row, { language }) => {
      return <>{row.dsaCategory ? DSA_CATEGORIES(language)[row.dsaCategory].long : ""}</>
    }
  },
  banDescription: {
    header: () => <>Details</>,
    cell: row => <>{row.description}</>
  }
}

const PaddedButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2)
}))

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row'
})

export function LoadMoreButton({ onClick, disabled }) {
  return (
    <ButtonContainer>
      <PaddedButton disabled={disabled} color="primary" startIcon={<Add />} onClick={onClick}>
        Load more
      </PaddedButton>
    </ButtonContainer>
  )
}

LoadMoreButton.propTypes = {
  onClick: func.isRequired,
  disabled: bool
}

LoadMoreButton.defaultProps = {
  disabled: false
}

export function PrevNextButtons({ onPrev, onNext, isPrevDisabled, isNextDisabled }) {
  return (
    <ButtonContainer>
      <PaddedButton
        disabled={isPrevDisabled}
        color="primary"
        startIcon={<ArrowLeft />}
        onClick={onPrev}
      >
        Prev
      </PaddedButton>
      <PaddedButton
        disabled={isNextDisabled}
        color="primary"
        endIcon={<ArrowRight />}
        onClick={onNext}
      >
        Next
      </PaddedButton>
    </ButtonContainer>
  )
}

PrevNextButtons.propTypes = {
  onPrev: func.isRequired,
  onNext: func.isRequired,
  isPrevDisabled: bool,
  isNextDisabled: bool
}

PrevNextButtons.defaultProps = {
  isPrevDisabled: false,
  isNextDisabled: false
}

export function Table({ data, columns, isLoading }) {
  const { language } = useSelector(settingsSelector)
  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>
            {columns.map((c, i) => (
              <TableHeaderCell key={`column_${i}`}>{c.header()}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            data.map((d, i) => (
              <TableRow key={`row_${i}`}>
                {columns.map((col, j) => (
                  <TableCell key={`column_${j}`}>{col.cell(d, { language })}</TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  )
}

Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: array.isRequired,
  columns: arrayOf(
    shape({
      header: func,
      cell: func
    })
  ).isRequired,
  isLoading: bool
}

Table.defaultProps = {
  isLoading: false
}
